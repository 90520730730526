<template>
    <v-row>
        <v-col cols="12" md="6" lg="4">
            <v-card>
                <v-card-text>Para começar, selecione a opção desejada no menu ao lado</v-card-text>
            </v-card>
        </v-col>
        <!--<v-col cols="12" md="6" lg="4">
            <v-card>
                <v-card-title>* Avisos</v-card-title>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4">
            <v-card>
                <v-card-title>* Últimas aulas</v-card-title>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4">
            <v-card>
                <v-card-title>* Cursos</v-card-title>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4">
            <v-card>
                <v-card-title>* Minhas turmas</v-card-title>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4">
            <v-card>
                <v-card-title>* Financeiro</v-card-title>
            </v-card>
        </v-col>-->
    </v-row>
</template>

<script>
export default {
name: "Dashboard"
}
</script>

<style scoped>

</style>